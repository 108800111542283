import {IWidgetError, IWidgetSizes} from "../../Models/SharedModels";
import {EStyleDisplay, IWidgetParams} from "../../types";
import {IFRAME_DOMAIN_URL, SDK_DOMAIN_URL, APP_BASE_PATH} from "../const";
import {FRAME_INITIAL_STYLES, STYLES_FULL_SCREEN_MODE} from '../iframe';
import {certificateVerify} from "../Widget/utils";
import {
    WIDGET_FRAME_CREATE_ERROR,
    WIDGET_FRAME_FULL_SCREEN_OFF_ERROR,
    WIDGET_FRAME_FULL_SCREEN_ON_ERROR,
    WIDGET_FRAME_LOADING_ERROR,
    WIDGET_FRAME_NOT_FOUND_ERROR
} from "./errors";
import {CSSStyle} from "./types";
import {objectForEach, parseIntWithoutNaN, setElementStyle} from "./utils";

/**
 * Фрейм виджета.
 */
export abstract class BaseWidgetFrame {
    abstract name: string;
    private container?: HTMLElement;
    private iframe?: HTMLIFrameElement;
    private fullScreenModeOn = false;
    private styles: CSSStyle = {};
    private containerSizeObserver: ResizeObserver;
    private sizes: IWidgetSizes;
    private fallback = false;

    constructor(
        protected main: boolean,
        protected params: IWidgetParams,
        protected onError: (error: IWidgetError, meta?: unknown) => void,
        protected onFallback?: () => void,
    ) {}

    /**
     * Установить значение о необходимости отрисовать состояние заглушки.
     *
     * @param fallback Значение состояния заглушки.
     */
    setFallback = (fallback: boolean) => this.fallback = fallback

    /**
     * Получение домена для загрузки фрейма.
     */
    getOrigin = () => this.fallback ? SDK_DOMAIN_URL : IFRAME_DOMAIN_URL;

    /** Получение фрейма. */
    getFrame = () => this.iframe;

    /**
     * Получение пути фрейма.
     */
    getRootPath = () => this.fallback ? '/stub/' : APP_BASE_PATH;

    /**
     * Создание фрейма.
     */
    create = async () => {
        const {widgetId, key, partnerId, container} = this.params;
        try {
            this.container = container;
            this.iframe = await this.innerCreate(widgetId, key, partnerId);
    
            this.containerSizeObserver = new ResizeObserver(this.reCalcFrameSizes);
            this.containerSizeObserver.observe(container);
        } catch (e) {
            this.destroy();
            this.onError(WIDGET_FRAME_CREATE_ERROR, e);
        }
    }

    /**
     * Проверка возможности загрузки фрейма.
     */
    private checkLoadFrame = async () => {
        const {disableCertVerify} = this.params;
        const fallback = this.main && !disableCertVerify && !(await certificateVerify());

        if (fallback) {
            this.setFallback(true);
            this.onFallback?.();
        }

        return Promise.resolve();
    }

    /**
     * Создание iframe.
     *
     * @param widgetId Идентификатор виджета.
     * @param key Ключ.
     * @param partnerId Идентификатор партнера.
     */
    private innerCreate = async (widgetId: string, key: string, partnerId: string): Promise<HTMLIFrameElement> => {
        await this.checkLoadFrame();

        const iframe = Object.assign(document.createElement('iframe'), {
            id: `${widgetId}_${this.name}`,
            src: this.getFrameSrc(widgetId, key, partnerId),
            onError: async (error: string | Event) => {
                this.onError(WIDGET_FRAME_LOADING_ERROR, error);
            },
        });

        this.container?.appendChild(iframe);
        setElementStyle(iframe, FRAME_INITIAL_STYLES);

        return iframe;
    };

    /**
     * Получить URL iframe.
     *
     * @param widgetId Идентификатор виджета.
     * @param key Ключ.
     * @param partnerId Идентификатор партнера.
     */
    abstract getFrameSrc(widgetId: string, key: string, partnerId: string): string;

    /**
     * Перерасчет размеров фрейма.
     */
    private reCalcFrameSizes = () => {
        if (!this.fullScreenModeOn && this.sizes && this.container && this.iframe) {
            const {offsetWidth} = this.container;
            const {minWidth, maxWidth, height} = this.sizes;
            const {marginLeft = '0', marginRight = '0'} = getComputedStyle(this.iframe);
            const marginHorizontal = parseIntWithoutNaN(marginLeft) + parseIntWithoutNaN(marginRight);
            
            const frameWidth = Math.max(
                minWidth,
                (maxWidth > 0 ? Math.min(offsetWidth, maxWidth) : offsetWidth) - marginHorizontal
            );

            this.setFrameStyles({
                width: `${frameWidth}px`,
                height: `${height}px`
            });
        }

    }

    /**
     * Удалить фрейм.
     */
    destroy = () => {
        this.containerSizeObserver?.disconnect();
        if (this.iframe) {
            this.iframe.src = '';
            this.iframe.remove();
        }
    }

    /**
     * Задать стили фрейму.
     *
     * @param styles Стили.
     * @param [lockInFullScreen] Блокировка обновлений стилей в полном экране.
     */
    setFrameStyles = (styles: CSSStyle, lockInFullScreen = true) => {
        this.iframe && (!lockInFullScreen || !this.fullScreenModeOn) && setElementStyle(this.iframe, styles);
    }

    /**
     * Получить стили фрейма.
     */
    getFrameStyles = () => this.styles;

    /**
     * Задать размеры фрейму.
     *
     * @param sizes Размеры.
     */
    setFrameSizes = (sizes: IWidgetSizes) => {
        this.sizes = sizes;
        this.reCalcFrameSizes();
    }

    /**
     * Проверка видим ли фрейм.
     */
    isShow = (): boolean => this.iframe?.style.display === EStyleDisplay.BLOCK;

    /**
     * Задать видимость фрейму.
     */
    setShow = (): void => {
        if (this.fullScreenModeOn) {
            this.setFullScreen(false);
        }
        this.setFrameStyles({...FRAME_INITIAL_STYLES, display: EStyleDisplay.BLOCK});
    };

    /**
     * Получить окно фрейма.
     */
    getWindow = (): Window => this.iframe?.contentWindow;

    /**
     * Задать фрейму полный экран.
     *
     * @param fullScreen Полный эран включить или выключить.
     */
    setFullScreen = (fullScreen: boolean) => {
        if (this.iframe) {
            try {
                if (fullScreen) {
                    // Если мы еще не в полном режиме запоминаем стили.
                    !this.fullScreenModeOn && objectForEach(STYLES_FULL_SCREEN_MODE, (key) => {
                        this.styles[key] = this.iframe.style[key];
                    });
                    // Задаем стили полного экрана.
                    this.setFrameStyles(STYLES_FULL_SCREEN_MODE);
                } else {
                    // Восстанавливаем стили.
                    this.setFrameStyles(this.styles, false);
                    this.styles = {};
                }

                this.fullScreenModeOn = fullScreen;
                return Promise.resolve();
            } catch (e) {
                this.onError(fullScreen ? WIDGET_FRAME_FULL_SCREEN_ON_ERROR : WIDGET_FRAME_FULL_SCREEN_OFF_ERROR, e);
                return Promise.reject(e);
            }
        } else {
            this.onError(WIDGET_FRAME_NOT_FOUND_ERROR);
            return Promise.reject(WIDGET_FRAME_NOT_FOUND_ERROR);
        }
    }
}
