import {IWidgetError} from "../../Models/SharedModels";
import {ELogType, IWidgetParams} from "../../types";
import {EPostMessageSystemName, MessageTypes} from "../../types/MessageTypes";
import {EventMessageService} from "../EventMessageService/EventMessageService";
import {WidgetError} from "../WidgetError";
import {BaseWidgetFrame} from "../WidgetFrame/BaseWidgetFrame";
import {WidgetFrame} from "../WidgetFrame/WidgetFrame";
import {WIDGET_READY_TIMEOUT, WIDGET_STYLE_APPLYED_TIMEOUT} from "../const";
import {ERROR_WIDGET_LOADING, WIDGET_CREATE_IMPOSSIBLE} from "../errors";
import {getErrorWithMeta} from "../utils";
import {IWidget} from "./types";
import {getTestingStatusByBankName} from "../../testData";
import {WidgetFrameSnapshot} from "../WidgetFrame/WidgetFrameSnapshot";
import {checkElementIsHidden} from "./utils";

/**
 * Класс виджета.
 */
export class Widget implements IWidget {
    private snapshot: BaseWidgetFrame;
    private frame: BaseWidgetFrame;
    private snapshotMessage: EventMessageService<EPostMessageSystemName, MessageTypes>;
    private message: EventMessageService<EPostMessageSystemName, MessageTypes>;

    constructor(protected params: IWidgetParams) {
        if (this.validateParams(params)) {
            this.start(params);
        }
    }

    private createFrames = async (params: IWidgetParams) => {
        this.snapshot = new WidgetFrameSnapshot(false, params, this.handleError);
        this.frame = new WidgetFrame(true, params, this.handleError, () => {
            this.snapshot.setFallback(true);
        });
        await this.frame.create();
        await this.snapshot.create();
    }

    /**
     * Старт виджета.
     *
     * @param params Параметры виджета.
     */
    private start = async (params: IWidgetParams) => {
        await this.createFrames(params);

        this.snapshotMessage = new EventMessageService<EPostMessageSystemName, MessageTypes>(this.snapshot?.getWindow(), this.snapshot.getOrigin());
        this.message = new EventMessageService<EPostMessageSystemName, MessageTypes>(this.frame?.getWindow(), this.frame.getOrigin());
        this.registerMessageListeners();

        // Удаляем iframe, если по прошествии времени он невидим (Не получено от виджета сообщение о готовности).
        setTimeout(() => {
            if (!this.frame?.isShow()) {
                this.destroy();
                this.handleError(ERROR_WIDGET_LOADING);
            }
        }, WIDGET_READY_TIMEOUT);
    }

    /**
     * Вывод отладочной информации.
     *
     * @param text Текст.
     * @param type Тип.
     */
    private debug = (text: string, type: ELogType = ELogType.LOG) => {
        const {widgetId, debugMode} = this.params;
        debugMode && console[type](`SberWidgetsSDK [Виджет с идентификатором ${widgetId}]: ${text}`);
    }

    /**
     * Обработчик ошибок.
     *
     * @param error Ошибка.
     */
    private handleError = (error: IWidgetError, meta?: string): IWidgetError => {
        const errorWithMeta = getErrorWithMeta(error, meta);

        this.params.onError?.(errorWithMeta);
        this.debug(`${errorWithMeta.errorCode}: ${errorWithMeta.errorMessage}`, ELogType.WARN);
        return errorWithMeta;
    }

    /**
     * Валидация параметров.
     * TODO Думаю стоит вынести в утилиты, с разграничением по widgetId.
     *
     * @param params Параметры виджета.
     */
    private validateParams = (params: IWidgetParams) => {
        const errors: string[] = [];
        const requiredParams: (keyof IWidgetParams)[] = ['widgetId', 'key', 'partnerId', 'container'];

        requiredParams.forEach(param => {
            !params[param] && errors.push(`Отсутствует ${param}`);
        })

        if (errors.length > 0) {
            const {errorCode, errorMessage} = this.handleError(WIDGET_CREATE_IMPOSSIBLE, errors.join(', '));
            throw new WidgetError(errorCode, errorMessage);
        }

        return true;
    }

    /** Проверка на clickjacking */
    private checkClickjacking = () => {
        setTimeout(() => {
            if (this.frame?.isShow() && checkElementIsHidden(this.frame.getFrame())) {
                this.destroy()
            }
        }, WIDGET_STYLE_APPLYED_TIMEOUT);
    }

    /**
     * Регистрируем слушатели сообщений.
     */
    private registerMessageListeners = () => {
        this.message.registerBefore((_0, _1, _2, data) => {
            // В Debug режиме выводим в консоль все postMessage.
            this.debug(`postMessage json: ${data}`);
        })
    
        // Регистрируем слушатель события о готовности виджета.
        this.message.register(EPostMessageSystemName.WIDGET_READY, () => {
            const {onSuccess, orderData} = this.params;
            const {amount: amountFromOrderData} = orderData || {};
            this.frame?.setShow();

            // TODO: Переработать механизм передачи данных до авторизации.
            // Передаем значение orderData.amount до авторизации.
            this.message.send(EPostMessageSystemName.PARTNER_READY, {amountFromOrderData});
            const infoText = 'Виджет готов';
    
            onSuccess?.(infoText);
            this.debug(`${infoText}`, ELogType.INFO);
        });

        this.message.register(EPostMessageSystemName.FULL_SCREEN_MODE, async ([on, content, htmlClassName], rquid) => {
            this.snapshotMessage.send(EPostMessageSystemName.RENDER_SNAPSHOT, [on ? content : '', htmlClassName]);
            try {
                await this.frame?.setFullScreen(on);
                this.snapshot.setFrameStyles({...this.frame.getFrameStyles(), display: on ? 'block' : 'none'});
                this.message.send(EPostMessageSystemName.FULL_SCREEN_MODE_RESPONSE, true, rquid);
            } catch (e) {
                this.message.send(EPostMessageSystemName.FULL_SCREEN_MODE_RESPONSE, false, rquid);
            }
        });
    
        // Регистрируем слушатель события перехода в полноэкранный режим.
        this.message.register(EPostMessageSystemName.FULL_SCREEN_MODE_ON, async () => {
            try {
                this.debug(`Разворачиваем виджет на весь экран`);
                await this.frame?.setFullScreen(true);
                this.message.send(EPostMessageSystemName.FULL_SCREEN_MODE_ENABLED, {});
            } catch (e) {
                this.message.send(EPostMessageSystemName.FULL_SCREEN_MODE_DISABLED, {errorMessage: JSON.stringify(e)});
            }
        });

        // Регистрируем слушатель события выхода из полноэкранного режима.
        this.message.register(EPostMessageSystemName.FULL_SCREEN_MODE_OFF, async () => {
            try {
                this.debug(`Выходим из полноэкранного отображения`);
                await this.frame?.setFullScreen(false);
                this.message.send(EPostMessageSystemName.FULL_SCREEN_MODE_DISABLED, {});
            } catch (e) {
                this.message.send(EPostMessageSystemName.FULL_SCREEN_MODE_ENABLED, {errorMessage: JSON.stringify(e)});
            }
        });

        // Регистрируем слушатель события редиректа.
        this.message.register(EPostMessageSystemName.REDIRECT, (payload) => {
            window.location.href = payload.url;
        });

        // Регистрируем слушатель события удаления виджета.
        this.message.register(EPostMessageSystemName.DESTROY, () => {
            this.destroy();
        });

        // Регистрируем слушатель события обновления стилей фрейма.
        this.message.register(EPostMessageSystemName.UPDATE_FRAME_STYLES, (payload) => {
            this.frame?.setFrameStyles(payload);
            this.message.send(EPostMessageSystemName.UPDATE_FRAME_STYLES_RESPONSE, null);
        });

        // Регистрируем слушатель события обновления стилей фрейма.
        this.message.register(EPostMessageSystemName.UPDATE_FRAME_SIZES, (payload) => {
            this.frame?.setFrameSizes(payload);
        });

        // Регистрируем виджет события на запрос данных по КВК.
        this.message.register(EPostMessageSystemName.REQUEST_DATA_KVK, (_, {rquid: requestRquid}) => {
            const {orderData, onSendOrderDataSuccess, onSendOrderDataError} = this.params;
            this.debug(`Получен запрос данных по платежке`);

            // Отправка данных по КВК.
            this.message.send(EPostMessageSystemName.RESPONSE_DATA_KVK, {...orderData}, {rquid: requestRquid});

            // Регистрируем слушатель события успешного получения данных по КВК.
            this.message.registerOnce(EPostMessageSystemName.SUCCESS_DATA_KVK, (payload, {rquid}) => {
                if (requestRquid === rquid) {
                    this.debug(`Данные по платежке получены`);
                    onSendOrderDataSuccess?.(payload);
                    this.message.remove(EPostMessageSystemName.DATA_ERROR_KVK);
                }
            });

            // Регистрируем слушатель события ошибки получения данных по КВК.
            this.message.registerOnce(EPostMessageSystemName.DATA_ERROR_KVK, (payload, {rquid}) => {
                if (requestRquid === rquid) {
                    this.debug(`Ошибка получения данных по платежке`, ELogType.ERROR);
                    onSendOrderDataError?.(payload);
                    this.message.remove(EPostMessageSystemName.SUCCESS_DATA_KVK);
                }
            });
        });

        // Регистрируем слушатель события получения статусов по платежкам.
        this.message.register(EPostMessageSystemName.REQUEST_STATUS_KVK_DATA, async (payload, {rquid: requestRquid}) => {
            const {onReceivedOrderDataStatuses} = this.params;
            this.debug(`Данные по статусам платежек получены`);

            if (onReceivedOrderDataStatuses) {
                try {
                    if (__NODE_ENV__ === 'test') { 
                        onReceivedOrderDataStatuses(getTestingStatusByBankName(this.params.orderData));
                    } else {
                        await onReceivedOrderDataStatuses(payload);
                    }
                    this.message.send(EPostMessageSystemName.SUCCESS_STATUS_KVK_DATA, payload, {rquid: requestRquid});
                } catch (error) {
                    this.message.send(EPostMessageSystemName.ERROR_STATUS_KVK_DATA, payload, {rquid: requestRquid});
                }
            }
        });
    }

    /**
     * Отправка сообщения.
     *
     * @param systemName Системное имя.
     * @param payload Полезная нагрузка.
     */
    sendMessage = (systemName: EPostMessageSystemName, payload?: unknown) => {
        this.message?.send(systemName, payload, null);
    }
 
    /**
     * Удаление виджета.
     */
    destroy = () => {
        const {container} = this.params;
        if (container) {
            if (! this.params.debugMode) {
                this.message?.destroy();
                this.snapshotMessage?.destroy();
                this.frame?.destroy();
                this.snapshot?.destroy();
                // container.remove(); //TODO: выбрать подход все внутри тереть или только iframe
            }
        } else {
            // TODO Проработать обработку ошибок.
            this.debug(`Не возможно удалить виджет. Виджет не найден.`, ELogType.WARN);
        }
    }
}
